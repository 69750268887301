import { combineReducers } from 'redux';
import { formReducer } from './form/formReducer';
import { globalReducer } from './global/globalReducer';
import { surveyReducer } from './survey/surveyReducer';

const rootReducer = combineReducers({
    global: globalReducer,
    survey: surveyReducer,
    form: formReducer,
});

export default rootReducer;
