import { IPersonaProps } from '@fluentui/react';
import { Action } from 'redux';
import { ISurvey } from '../../models/Survey';

export const SURVEY_LOAD_REQUEST_BY_ID = 'SURVEY_LOAD_REQUEST_BY_ID';
export const SURVEY_LOAD_SUCCESS_BY_ID = 'SURVEY_LOAD_SUCCESS_BY_ID';
export const SURVEY_LOAD_FAIL_BY_ID = 'SURVEY_LOAD_FAIL_BY_ID';
export const SURVEY_RESET_BY_ID = 'SURVEY_RESET_BY_ID';

export const SURVEY_LOAD_REQUEST_BY_FUNCTION = 'SURVEY_LOAD_REQUEST_BY_FUNCTION';
export const SURVEY_LOAD_SUCCESS_BY_FUNCTION = 'SURVEY_LOAD_SUCCESS_BY_FUNCTION';
export const SURVEY_LOAD_FAIL_BY_FUNCTION = 'SURVEY_LOAD_FAIL_BY_FUNCTION';
export const SURVEY_RESET_BY_FUNCTION = 'SURVEY_RESET_BY_FUNCTION';

export interface SurveyLoadRequestById extends Action<typeof SURVEY_LOAD_REQUEST_BY_ID> {}
export interface SurveyLoadSuccessById extends Action<typeof SURVEY_LOAD_SUCCESS_BY_ID> {
    payload: {
        fetchedSurvey: ISurvey;
    };
}
export interface SurveyLoadFailById extends Action<typeof SURVEY_LOAD_FAIL_BY_ID> {
    payload: {
        fetchError: string;
    };
}

export interface ResetSurveyById extends Action<typeof SURVEY_RESET_BY_ID> {}

export interface SurveyLoadRequestByFunction extends Action<typeof SURVEY_LOAD_REQUEST_BY_FUNCTION> {}

export interface SurveyLoadSuccessByFunction extends Action<typeof SURVEY_LOAD_SUCCESS_BY_FUNCTION> {
    payload: {
        fetchedSurvey: ISurvey;
        fetchedUsers: IPersonaProps[];
    };
}
export interface SurveyLoadFailByFunction extends Action<typeof SURVEY_LOAD_FAIL_BY_FUNCTION> {
    payload: {
        fetchError: string;
    };
}

export interface ResetSurveyByFunction extends Action<typeof SURVEY_RESET_BY_FUNCTION> {}
