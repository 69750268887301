import React from 'react';
import Footer from '../footer/Footer';
import Loading from '../loading/Loading';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner: React.FC = () => (
    <div className={styles.container}>
        <div className={styles.box}>
            <Loading />
            <Footer />
        </div>
    </div>
);

export default LoadingSpinner;
