import React from 'react';
import logo from '../../images/fresenius-logo.png';
import styles from './Header.module.scss';

const Header: React.FC = (): React.ReactElement => (
    <div className={styles.header} data-version="1">
        <img className={styles.logo} width={165} height={45} src={logo} alt="Fresenius Logo" />
    </div>
);

export default Header;
