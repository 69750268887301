import { createTheme } from '@fluentui/react';

export const appTheme = createTheme({
    palette: {
        themePrimary: '#0063be',
        themeLighterAlt: '#f2f8fc',
        themeLighter: '#cee2f5',
        themeLight: '#a5caec',
        themeTertiary: '#579ad9',
        themeSecondary: '#1872c7',
        themeDarkAlt: '#0059ac',
        themeDark: '#004b91',
        themeDarker: '#00376b',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#d0d0d0',
        neutralSecondary: '#a2a2a2',
        neutralPrimaryAlt: '#767676',
        neutralPrimary: '#636363',
        neutralDark: '#4c4c4c',
        black: '#383838',
        white: '#ffffff',
    },
    defaultFontStyle: { fontFamily: 'Verdana, sans-serif' },
});
