import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { initializeIcons, ThemeProvider } from '@fluentui/react';

import { appTheme } from './theme/theme';

import Header from './components/header/Header';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import LoadingSpinner from './components/loadingSpinner/LoadingSpinner';
import './App.css';

const WelcomePage = lazy(() => import('./pages/welcomePage/WelcomePage'));
const CompletedPage = lazy(() => import('./pages/completedPage/CompletedPage'));
const CompletedFieldEntryPage = lazy(() => import('./pages/completedFieldEntryPage/CompletedFieldEntryPage'));
const NotFoundPage = lazy(() => import('./pages/notFoundPage/NotFoundPage'));
const InitiatePage = lazy(() => import('./pages/initiatePage/InitiatePage'));
const ScannedPage = lazy(() => import('./pages/scannedPage/ScannedPage'));
const QRCodesPage = lazy(() => import('./pages/QRCodesPage/QRCodesPage'));

const App: React.FC = (): React.ReactElement => {
    useEffect(() => {
        initializeIcons();
    }, []);
    return (
        <BrowserRouter>
            <ThemeProvider theme={appTheme}>
                <Header />
                <ErrorBoundary>
                    <Suspense fallback={<LoadingSpinner />}>
                        <Switch>
                            <Route exact path="/" component={WelcomePage} />
                            <Route path="/initiate" component={InitiatePage} />
                            <Route path="/complete" component={CompletedPage} />
                            <Route path="/completeFieldEntry" component={CompletedFieldEntryPage} />
                            <Route path="/scannedCode" component={ScannedPage} />
                            <Route path="/QRCodes" component={QRCodesPage} />
                            <Route component={NotFoundPage} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
