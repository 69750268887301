import * as React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { IStackProps, Stack } from '@fluentui/react/lib/Stack';

const Loading: React.FC = (): React.ReactElement => {
    const rowProps: IStackProps = { horizontal: false, verticalAlign: 'center' };

    const tokens = {
        sectionStack: {
            childrenGap: 10,
        },
        spinnerStack: {
            childrenGap: 20,
        },
    };

    return (
        <Stack tokens={tokens.sectionStack}>
            <Stack {...rowProps} tokens={tokens.spinnerStack}>
                <Spinner size={SpinnerSize.large} />
            </Stack>
        </Stack>
    );
};

export default Loading;
