import { IPersonaProps } from '@fluentui/react';
import { Action } from 'redux';

export const FORM_SET_EMPLOYEES = 'FORM_SET_EMPLOYEES';
export const FORM_SET_COMPANY_NAME = 'FORM_SET_COMPANY_NAME';
export const FORM_SET_COMPANY_LOCATION = 'FORM_SET_COMPANY_LOCATION';
export const FORM_SET_CONTACT_NAME = 'FORM_SET_CONTACT_NAME';
export const FORM_SET_CONTACT_PHONE = 'FORM_SET_CONTACT_PHONE';
export const FORM_SET_CONTACT_EMAIL = 'FORM_SET_CONTACT_EMAIL';
export const FORM_SET_FK_EMPLOYEE = 'FORM_SET_FK_EMPLOYEE';
export const FORM_SET_PURPOSE = 'FORM_SET_PURPOSE';
export const FORM_RESET = 'FORM_RESET';

export interface FormSetEmployess extends Action<typeof FORM_SET_EMPLOYEES> {
    payload: {
        employees: IPersonaProps[];
    };
}
export interface FormSetCompanyName extends Action<typeof FORM_SET_COMPANY_NAME> {
    payload: {
        companyName: string;
    };
}
export interface FormSetCompanyLocation extends Action<typeof FORM_SET_COMPANY_LOCATION> {
    payload: {
        companyLocation: string;
    };
}
export interface FormSetContactName extends Action<typeof FORM_SET_CONTACT_NAME> {
    payload: {
        contactName: string;
    };
}
export interface FormSetContactPhone extends Action<typeof FORM_SET_CONTACT_PHONE> {
    payload: {
        contactPhone: string;
    };
}
export interface FormSetContactEmail extends Action<typeof FORM_SET_CONTACT_EMAIL> {
    payload: {
        contactEmail: string;
    };
}
export interface FormSetFKEmployee extends Action<typeof FORM_SET_FK_EMPLOYEE> {
    payload: {
        fkEmployee: IPersonaProps;
    };
}
export interface FormSetPurpose extends Action<typeof FORM_SET_PURPOSE> {
    payload: {
        purpose: string;
    };
}

export interface FormReset extends Action<typeof FORM_RESET> {}
