import { ISurvey } from '../../models/Survey';
import {
    ResetSurveyByFunction,
    ResetSurveyById,
    SurveyLoadFailByFunction,
    SurveyLoadFailById,
    SurveyLoadRequestByFunction,
    SurveyLoadRequestById,
    SurveyLoadSuccessByFunction,
    SurveyLoadSuccessById,
    SURVEY_LOAD_FAIL_BY_FUNCTION,
    SURVEY_LOAD_FAIL_BY_ID,
    SURVEY_LOAD_REQUEST_BY_FUNCTION,
    SURVEY_LOAD_REQUEST_BY_ID,
    SURVEY_LOAD_SUCCESS_BY_FUNCTION,
    SURVEY_LOAD_SUCCESS_BY_ID,
    SURVEY_RESET_BY_FUNCTION,
    SURVEY_RESET_BY_ID,
} from './surveyTypes';

interface State {
    fetchedSurveyById: ISurvey | null;
    fetchedSurveyByFunction: ISurvey | null;
    fetchedUsers: any;
    isFetchingById: boolean;
    isFetchingByFunction: boolean;
    fetchErrorById: string;
    fetchErrorByFunction: string;
}

const INITIAL_STATE: State = {
    fetchedSurveyById: null,
    fetchedSurveyByFunction: null,
    fetchedUsers: null,
    isFetchingById: true,
    isFetchingByFunction: true,
    fetchErrorById: '',
    fetchErrorByFunction: '',
};

export const surveyReducer = (
    state: State = INITIAL_STATE,
    action:
        | SurveyLoadRequestById
        | SurveyLoadSuccessById
        | SurveyLoadFailById
        | ResetSurveyById
        | SurveyLoadRequestByFunction
        | SurveyLoadSuccessByFunction
        | SurveyLoadFailByFunction
        | ResetSurveyByFunction,
) => {
    switch (action.type) {
        case SURVEY_LOAD_REQUEST_BY_ID: {
            return {
                ...state,
                isFetchingById: true,
            };
        }
        case SURVEY_LOAD_SUCCESS_BY_ID: {
            return {
                ...state,
                isFetchingById: false,
                fetchErrorById: '',
                fetchedSurveyById: action.payload.fetchedSurvey,
            };
        }
        case SURVEY_LOAD_FAIL_BY_ID: {
            return {
                ...state,
                isFetchingById: false,
                fetchErrorById: action.payload.fetchError,
            };
        }
        case SURVEY_RESET_BY_ID: {
            return {
                ...state,
                fetchedSurveyById: null,
                isFetchingById: true,
                fetchErrorById: '',
            };
        }
        case SURVEY_LOAD_REQUEST_BY_FUNCTION: {
            return {
                ...state,
                isFetchingByFunction: true,
            };
        }
        case SURVEY_LOAD_SUCCESS_BY_FUNCTION: {
            return {
                ...state,
                isFetchingByFunction: false,
                fetchErrorByFunction: '',
                fetchedSurveyByFunction: action.payload.fetchedSurvey,
                fetchedUsers: action.payload.fetchedUsers,
            };
        }
        case SURVEY_LOAD_FAIL_BY_FUNCTION: {
            return {
                ...state,
                isFetchingByFunction: false,
                fetchErrorByFunction: action.payload.fetchError,
            };
        }
        case SURVEY_RESET_BY_FUNCTION: {
            return {
                ...state,
                fetchedSurveyByFunction: null,
                fetchedUsers: null,
                isFetchingByFunction: true,
                fetchErrorByFunction: '',
            };
        }
        default:
            return state;
    }
};
