import { IPersonaProps } from '@fluentui/react';
import {
    FormReset,
    FormSetCompanyLocation,
    FormSetCompanyName,
    FormSetContactEmail,
    FormSetContactName,
    FormSetContactPhone,
    FormSetEmployess,
    FormSetFKEmployee,
    FormSetPurpose,
    FORM_RESET,
    FORM_SET_COMPANY_LOCATION,
    FORM_SET_COMPANY_NAME,
    FORM_SET_CONTACT_EMAIL,
    FORM_SET_CONTACT_NAME,
    FORM_SET_CONTACT_PHONE,
    FORM_SET_EMPLOYEES,
    FORM_SET_FK_EMPLOYEE,
    FORM_SET_PURPOSE,
} from './formTypes';

interface State {
    employees: IPersonaProps[];
    companyName: string;
    companyLocation: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    fkEmployee: IPersonaProps | undefined;
    purpose: string;
}

const INITIAL_STATE: State = {
    employees: [],
    companyName: '',
    companyLocation: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    fkEmployee: undefined,
    purpose: '',
};

export const formReducer = (
    state: State = INITIAL_STATE,
    action:
        | FormSetEmployess
        | FormSetCompanyName
        | FormSetCompanyLocation
        | FormSetContactName
        | FormSetContactPhone
        | FormSetContactEmail
        | FormSetFKEmployee
        | FormSetPurpose
        | FormReset,
) => {
    switch (action.type) {
        case FORM_SET_EMPLOYEES: {
            return {
                ...state,
                employees: action.payload.employees,
            };
        }
        case FORM_SET_COMPANY_NAME: {
            return {
                ...state,
                companyName: action.payload.companyName,
            };
        }
        case FORM_SET_COMPANY_LOCATION: {
            return {
                ...state,
                companyLocation: action.payload.companyLocation,
            };
        }
        case FORM_SET_CONTACT_NAME: {
            return {
                ...state,
                contactName: action.payload.contactName,
            };
        }
        case FORM_SET_CONTACT_PHONE: {
            return {
                ...state,
                contactPhone: action.payload.contactPhone,
            };
        }
        case FORM_SET_CONTACT_EMAIL: {
            return {
                ...state,
                contactEmail: action.payload.contactEmail,
            };
        }
        case FORM_SET_FK_EMPLOYEE: {
            return {
                ...state,
                fkEmployee: action.payload.fkEmployee,
            };
        }
        case FORM_SET_PURPOSE: {
            return {
                ...state,
                purpose: action.payload.purpose,
            };
        }
        case FORM_RESET: {
            return {
                employees: [],
                companyName: '',
                companyLocation: '',
                contactName: '',
                contactPhone: '',
                contactEmail: '',
                fkEmployee: undefined,
                purpose: '',
            };
        }
        default:
            return state;
    }
};
