import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';

const midlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    midlewares.push(logger as any);
}

const initialState = {};

const store = createStore(rootReducer, initialState, applyMiddleware(...midlewares));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
