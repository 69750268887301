import {
    GlobalReset,
    GlobalSetComments,
    GlobalSetError,
    GlobalSetFeedback,
    GlobalSetScore,
    GLOBAL_RESET,
    GLOBAL_SET_COMMENTS,
    GLOBAL_SET_ERROR,
    GLOBAL_SET_FEEDBACK,
    GLOBAL_SET_SCORE,
} from './globalTypes';

interface State {
    score: any[];
    feedback: string[];
    comments: string;
    error: string;
}

const INITIAL_STATE: State = {
    score: [],
    feedback: [],
    comments: '',
    error: '',
};

export const globalReducer = (
    state: State = INITIAL_STATE,
    action: GlobalSetScore | GlobalSetFeedback | GlobalSetError | GlobalSetComments | GlobalReset,
) => {
    switch (action.type) {
        case GLOBAL_SET_SCORE: {
            return {
                ...state,
                score: action.payload.score,
            };
        }
        case GLOBAL_SET_FEEDBACK: {
            return {
                ...state,
                feedback: action.payload.feedback,
            };
        }
        case GLOBAL_SET_COMMENTS: {
            return {
                ...state,
                comments: action.payload.comments,
            };
        }
        case GLOBAL_SET_ERROR: {
            return {
                ...state,
                error: action.payload.error,
            };
        }
        case GLOBAL_RESET: {
            return {
                score: [],
                feedback: [],
                comments: '',
                error: '',
            };
        }
        default:
            return state;
    }
};
